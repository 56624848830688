import React from "react";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "@utilities/link-resolver.js";
//import { withPreview } from "gatsby-source-prismic-graphql";
import moment from "moment";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";

import "@scss/event-single.scss";

export const eventQuery = graphql`
  query eventQuery($uid: String) {
    allPrismicEvent(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          data {
            title
            content {
              html
              text
              raw
            }
            event_cta {
              link_type
              ... on PrismicLinkType {
                url
                uid
              }
            }
            event_cta_text
            event_thumbnail {
              url
            }
            event_image {
              url
            }
          }
          uid
        }
      }
    }
  }
`;

const Event = (props) => {
  var data = props.data;
  const currentPage = get(data, "allPrismicEvent.edges[0].node");
  console.log("currentPage", currentPage);
  // const externalLink = get(currentPage, "event_cta", null);
  const date = moment(currentPage.data.date);
  if (currentPage.event_cta) {
    var external =
      currentPage.data.event_cta.__typename === "PRISMIC__ExternalLink"
        ? true
        : false;
  } else {
    var hideCTA = true;
  }
  return (
    <Layout>
      <div className="event-single" id={currentPage.uid}>
        <section>
          <h2>{currentPage.data.title}</h2>
          <time>
            {date.format("dddd, MMMM Do YYYY")}
            <span>|</span>
            {date.format("h:mm a")}
          </time>
          <div className="event-single__description">
            {RichText.render(currentPage.data.content, linkResolver)}
          </div>
          <div className="event-single__cta">
            {external && (
              <a href={currentPage.data.event_cta.url}>
                {currentPage.data.event_cta_text}
              </a>
            )}
            {!external && !hideCTA && (
              <Link to={currentPage.data.event_cta.url}>
                {currentPage.data.event_cta_text}
              </Link>
            )}
          </div>
          <img src={currentPage.data.event_image.url} />
          <div
            className="event-single__body"
            dangerouslySetInnerHTML={{ __html: currentPage.data.content.html }}
          ></div>
        </section>
      </div>
    </Layout>
  );
};

export default Event;
